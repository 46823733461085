<script setup lang="ts">
import type { DefaultLayoutQuery } from '#gql';

withDefaults(defineProps<{
  mainNavigationItems: DefaultLayoutQuery['mainNavigation']['items'];
}>(), {  })

const nuxtApp = useNuxtApp();

const {
  getSelectedSecondLevelIndex,
  getSelectedThirdLevelIndex,
  isSecondLevelOpen,
  isThirdLevelOpen,
} = useBurgerNavigation();

const { locale } = useI18n();

const GqlInstance = useGql();

const getClasses = computed(() => cn('transform transition-all ease-in-out', {
  'translate-x-[-100%]': !isSecondLevelOpen.value && !isThirdLevelOpen.value,
  'translate-x-[-200%]': isSecondLevelOpen.value,
  'translate-x-[-300%]': isThirdLevelOpen.value,
}));

const firstLevel = computed(() => __props.mainNavigationItems?.[getSelectedSecondLevelIndex.value]);

const {
  data,
  status,
} = await useAsyncData(
  `navigation-item-${firstLevel.value?._uid}-${locale.value}`,
  async () => {
    if (firstLevel.value) {
      return GqlInstance('NavigationItem', {
        id: firstLevel.value._uid || '',
        localeCode: locale.value,
      });
    }
    return null;
  },
  {
    getCachedData: (key) => (nuxtApp.static.data[key] || nuxtApp.payload.data[key]),
    watch: [ firstLevel ],
  },
);

const isLoading = computed(() => firstLevel.value && status.value === 'pending');

const secondLevel = computed(() => data?.value?.navigationItem.groups?.[getSelectedThirdLevelIndex.value]);
</script>

<template>
  <div class="burger-menu-content relative z-10 grid grid-cols-[repeat(3,minmax(100%,1fr))]">
    <LazyBurgerMenuFirstLevel
      v-if="!isLoading && mainNavigationItems"
      :class="getClasses"
      :items="mainNavigationItems"
    />
    <LazyBurgerMenuSecondLevel
      v-if="!isLoading"
      :class="getClasses"
      :items="data?.navigationItem.groups"
      :parent="firstLevel?.title"
    />
    <LazyBurgerMenuThirdLevel
      v-if="!isLoading"
      :class="getClasses"
      :items="secondLevel?.columns"
      :parent="secondLevel?.title"
    />
  </div>
  <UiLoadingSpinner
    v-if="isLoading"
    is-small
    class="flex self-center justify-self-center"
  />
</template>
