<script setup lang="ts">
import { XCircleIcon } from 'lucide-vue-next';

const { setIsBurgerNavigationVisible } = useBurgerNavigation();
</script>

<template>
  <div
    class="burger-menu-header fixed top-0 z-30 flex h-14 w-full items-center justify-between border-b-2 border-grey-light bg-white bg-scroll px-4"
    @click="setIsBurgerNavigationVisible(false)"
  >
    <LogoComponent class="m-0! h-[13.5]" />
    <XCircleIcon class="size-6 cursor-pointer text-dark" />
  </div>
</template>
