<script setup lang="ts">
import {
  BookOpenTextIcon,
  CircleHelpIcon,
  HeadsetIcon,
} from 'lucide-vue-next';
import type { DefaultLayoutQuery } from '#gql';

defineProps<{ headerData: DefaultLayoutQuery['header'] }>();

const {
  isSecondLevelOpen,
  isThirdLevelOpen,
} = useBurgerNavigation();

const { BASE_URL } = useBaseUrl();

const { getStoryblokUrl } = useStoryblokData();

const creatorSuiteLink = `${BASE_URL}/creator-suite`;

const isFirstLevelOpen = computed(() => !isSecondLevelOpen.value && !isThirdLevelOpen.value);
</script>

<template>
  <div class="burger-menu-footer relative w-full bg-grey-light">
    <div
      v-if="isFirstLevelOpen"
      class="relative z-20 flex justify-center bg-white bg-linear-to-b from-white from-50% to-grey-light to-50% px-4 pb-3 pt-5"
    >
      <CtaButton
        v-if="headerData.ctaButtonText"
        full-width
        :button-link-storyblok="getStoryblokUrl(headerData.ctaButtonLinkLeft)"
        :button-text="headerData.ctaButtonText"
        :button-text-logged-in="headerData.ctaButtonTextLoggedIn"
      />
    </div>
    <NuxtLink
      v-if="isFirstLevelOpen"
      class="mt-1 flex justify-center text-xs underline"
      :to="creatorSuiteLink"
    >
      {{ $t('common.creatorLogin') }}
    </NuxtLink>
    <div
      class="relative z-10 w-full px-4 pb-6 font-semibold"
      :class="{'mt-1': !isFirstLevelOpen}"
    >
      <HeaderTopLink
        v-if="headerData.magazine?.length"
        class="border-b border-b-grey py-2.5 pt-5"
        :header-link-content="headerData.magazine"
        :icon="BookOpenTextIcon"
      />
      <HeaderTopLink
        class="border-b border-b-grey py-2.5"
        :header-link-content="headerData.help"
        :icon="CircleHelpIcon"
      />
      <HeaderTopLink
        is-phone-number
        class="py-2.5"
        :header-link-content="headerData.phone"
        :icon="HeadsetIcon"
      />
    </div>
  </div>
</template>
